import React, { FC } from 'react'
import { LoginForm } from '../forms/LoginForm'

export const LoginPage: FC = () => {
    return (
        <div className='h-screen w-full flex items-center justify-center'>
            <div className='flex flex-col gap-5'>
                <div className='text-center text-primary font-bold text-2xl'>
                    FKAM URL SHORTENER
                </div>
                <LoginForm />
            </div>
        </div>
    )
}
