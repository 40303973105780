import axios from "axios"
import { LoginRequest } from "../../types"
import { axiosInstance } from "../../utils/https.util"

export const login = async (data: LoginRequest) => {
    const response = await axiosInstance.post('/login', data)
    return response
}

export const loginServerSide = async (data: LoginRequest) => {
    const response = await axios.post(`/api/login`, data)
    return response
}
