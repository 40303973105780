import { useFormik } from 'formik'
import React, { FC, useState } from 'react'
import * as Yup from 'yup'
import { loginServerSide } from '../../services/auth/auth.service'
import Swal from 'sweetalert2'
import { useRouter } from 'next/router'
import { Button, Input } from 'antd'
import * as jose from 'jose'
import jwt from 'jsonwebtoken'
import * as coookie from 'cookies-next'
import { COOKIE_NAME, COOKIE_NAME_API } from '../../data/cookie'

export const LoginForm: FC = () => {
    const router = useRouter()
    const [loading, setLoading] = useState<boolean>(false)

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: Yup.object({
            email: Yup.string().email('harus format email').required('Wajib diisi'),
            password: Yup.string().required('Wajib diisi')
        }),
        onSubmit: async (values) => {
            if (!loading) {
                setLoading(true)
                const payload = { ...values }
                loginServerSide(payload)
                    .then(async resp => {
                        console.log(resp);
                        console.log("decoded token", jwt.decode(resp?.data?.token))
                        const SECRET_KEY = process.env.NEXT_PUBLIC_SECRET_KEY
                        const decoded = await jwt.decode(resp?.data?.token) as jose.JWTPayload
                        const jwtToken = await new jose.SignJWT({ ...decoded })
                            .setProtectedHeader({ alg: 'HS256' })
                            .setIssuedAt()
                            .setExpirationTime('30d')
                            .sign(new TextEncoder().encode(SECRET_KEY));
                        coookie.setCookie(COOKIE_NAME, jwtToken)
                        coookie.setCookie(COOKIE_NAME_API, resp?.data?.token)
                        // router.push('/')
                        window.location.pathname = '/'
                        // alert('sukses')
                    })
                    .catch(err => {
                        Swal.fire({
                            title: 'Gagal Login!',
                            text: err.response.data.message || err.message,
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }
        }
    })

    return (
        <div className='rounded-lg border p-5 bg-white'>
            <form onSubmit={formik.handleSubmit}
                className="flex flex-col gap-2"
            >
                <div>
                    <Input
                        {...formik.getFieldProps("email")}
                        type="email"
                        placeholder="Alamat email"
                    />
                    {(formik.errors.email && formik.touched.email) ?
                        <div className='text-rose-500 text-xs italic pt-1'>
                            {formik.errors.email}
                        </div>
                        : null
                    }
                </div>
                <div>
                    <Input.Password
                        {...formik.getFieldProps("password")}
                        placeholder="Kata sandi"
                    />
                    {(formik.errors.password && formik.touched.password) ?
                        <div className='text-rose-500 text-xs italic pt-1'>
                            {formik.errors.password}
                        </div>
                        : null
                    }
                </div>

                <div>
                    <Button
                        type="primary"
                        htmlType='submit'
                        className={`w-full ${(loading || !formik.isValid) ? 'cursor-not-allowed bg-slate-300' : 'bg-blue-500 text-white'}`}
                    >
                        {loading ? 'Loading...' : 'Login'}
                    </Button>
                </div>
            </form>
        </div>
    )
}
