import { GetServerSideProps } from 'next'
import React from 'react'
import { BlankLayout } from '../components/layouts/BlankLayout'
import { HeadMeta } from '../components/meta/HeadMeta'
import { LoginPage } from '../components/pages/LoginPage'
import * as cookie from 'cookies-next'
import { COOKIE_NAME, COOKIE_NAME_API } from '../data/cookie'
import { jwtVerify } from 'jose'
import { verify } from 'jsonwebtoken'

export const getServerSideProps: GetServerSideProps = async ({ req, res }) => {
    const token = await req.cookies[COOKIE_NAME] as string
    // const token = await cookie.getCookie(COOKIE_NAME) as string;
    const SECRET_KEY: string = process.env.NEXT_PUBLIC_SECRET_KEY as string;
    console.log("SECRET_KEY", SECRET_KEY);
    console.log("token client as server", token);

    try {
        const { payload } = await jwtVerify(token, new TextEncoder().encode(SECRET_KEY));
        // const payload = await verify(token, SECRET_KEY)
        console.log("payload => ", payload);
        if (token && payload) {
            return {
                redirect: {
                    destination: '/',
                    permanent: false
                }
            }
        }
    } catch (error) {
        console.log("gagal => ", error);

        cookie.deleteCookie(COOKIE_NAME)
        cookie.deleteCookie(COOKIE_NAME_API)
    }

    return {
        props: {}, // will be passed to the page component as props
    }
}

export default function Login() {
    return (
        <>
            <HeadMeta />

            <BlankLayout>
                <LoginPage />
            </BlankLayout>
        </>
    )
}
